/* ==========================================================================
   elements.fonts
   ========================================================================== */

@font-face {
  font-family: 'FaktPro-Blond';
  src: url('../fonts/FaktPro-Blond.eot'); /* IE9 Compat Modes */
  src: url('../fonts/FaktPro-Blond.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/FaktPro-Blond.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/FaktPro-Blond.ttf')  format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'FaktPro-Normal';
  src: url('../fonts/FaktPro-Normal.eot');
  src: url('../fonts/FaktPro-Normal.eot?#iefix') format('ie9-skip-eot'),
       url('../fonts/FaktPro-Normal.woff') format('woff'),
       url('../fonts/FaktPro-Normal.ttf')  format('truetype');
}

@font-face {
  font-family: 'FaktPro-Bold';
  src: url('../fonts/FaktPro-Bold.eot');
  src: url('../fonts/FaktPro-Bold.eot?#iefix') format('ie9-skip-eot'),
       url('../fonts/FaktPro-Bold.woff') format('woff'),
       url('../fonts/FaktPro-Bold.ttf')  format('truetype');
}