/* ==========================================================================
   components.hero
   ========================================================================== */

.c-hero {
	min-height: 600px;

	padding: 80px 0;

	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	position: relative;

	@include breakpoint($mobilexxs) {
		min-height: auto;

		padding: 50px 0;
	}
}


/**
 * Specific styling for homepage
 */

.c-hero--home {
	width: 100%;

	@include breakpoint($mobilexxxs) {
		background-position: -800px 0;
	}
}

.c-hero--default {
	min-height: 600px;

	padding: 0;

	@include breakpoint($mobilexxs) {
		min-height: 380px;		
	}
}

.c-hero--slim {
	min-height: 550px;

	padding: 0;

	@include breakpoint($mobilexxs) {
		min-height: 380px;
	}
}

.c-hero__content {
	max-width: 655px;

	p {
		font-size: 24px;
		line-height: 1.5;
	}

	@include breakpoint($mobilexxs) {
		p {
			font-size: 18px;
		}
	}
}

.c-hero__title {
	font-size: 3.500em;

	@include breakpoint($mobilexxs) {
		font-size: 1.500em;
	}
}