/* ==========================================================================
   components.date
   ========================================================================== */

.c-date {
	
}

.c-date__divider {
	padding: 0 5px;
}