/* ==========================================================================
   components.article
   ========================================================================== */

.c-article {
	background-color: $color-white;

	-webkit-box-shadow: 0px 2px 10px 2px rgba(0,0,0,0.10);
	-moz-box-shadow: 0px 2px 10px 2px rgba(0,0,0,0.10);
	box-shadow: 0px 2px 10px 2px rgba(0,0,0,0.10);
}

.c-article__container {
	padding: 15px 110px;

	@include breakpoint($tablet) {
		padding: 0 60px;
	}

	@include breakpoint($mobilexxs) {
		padding: 0 25px;
	}
}

.c-article__block--obese {
	padding: 30px 0;
}

.c-article p {
	margin-bottom: $spacing-default;
}

.c-article {
	ul,
	ol {
		
		margin-bottom: $spacing-default;
		margin-left: 0;
		padding-left: 0;
		color: $color-body;

		list-style-position: inside;
		list-style: none;
		counter-reset: counter;

		li {
			position: relative;

			margin-bottom: 15px;
			padding-left: 22px;

			counter-increment: counter;
		}
	}

	ul li::before {
		content: "";

		height: 8px;
		width: 8px;

		
		background-color: $color-secondary;

		position: absolute;
		top: 9px;
		left: 0;

		border-radius: 100%;		
	}

	ol li::before {
		font-weight: bold;
		content: counter(counter);
		color: $color-primary;
		margin-right: 10px;
	}
}