/*! Generic - Base */

/**
 * Set the document root font size at 10px. This allows for
 * easy calculation of REM sizes.
 */

:root {
  font-size: 62.5%;
}

/**
 * Set base font family, size and baseline
 * as defined in (_settings.colors).
 */

body {
  font-family: $font-family-blond;
  font-size: $font-size-default;

  line-height: $font-baseline;

  background-color: $color-background-body;
  color: $color-body;
}

/**
 * Set global placeholder color as defined in (_settings.colors).
 */

placeholder {
  color: $color-placeholder;
}

/**
 * Overrule default selection color as deefined in (_settings.colors).
 */

::selection {
  background: $color-select;
}