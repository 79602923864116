/* ==========================================================================
   components.quote
   ========================================================================== */

 .c-quote {
 	width: 100%;
 	min-height: inherit;

 	display: flex;
 	align-items: flex-end;

 	position: relative;
 	z-index: $z-index-8;
 }

 .c-quote__content {
 	width: 75%;

 	padding: $spacing-default;
 	margin-bottom: $spacing-default;

 	@include breakpoint($mobilexxs) {
 		width: 100%;
 	}
 }

 .c-quote__title {
 	font-size: 2.250em;
 	font-family: $font-family-bold;

 	line-height: 1.1;

 	color: $color-white;

 	padding-bottom: ($spacing-small/2);

 	@include breakpoint($mobilexxs) {
 		font-size: 1.5em;
 	}
 }

  .c-quote__name {
  	font-size: $font-size-m;
  	color: $color-white;
  }