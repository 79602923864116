/* ==========================================================================
   components.main-nav
   ========================================================================== */

.c-main-nav .nav {
	@include breakpoint($navhide) {
		display: none;
	}
}

.c-main-nav .nav li {
	list-style: none;

	display: inline-block;
}

.c-main-nav .nav li a {
	font-family: $font-family-normal;

	color: $color-primary;

	text-decoration: none;

	padding: $spacing-small;

	display: block;
}

.c-main-nav .nav li:last-child a {
	padding-right: $spacing-default;
}

.c-main-nav .nav li a:hover,
.c-main-nav .nav li.active a {
	color: $color-secondary;
}


/**
 * IE9 Layout fixes
 */

.ie9 .c-main-nav {
	float: left;
}