/* ==========================================================================
   objects.pane
   ========================================================================== */

/**
 * A pane is a full width band with additional padding on each side to provide some
 * space on narrow viewport.
 */

.o-pane {
  display: block;

  padding-right: $spacing-small;
  padding-left: $spacing-small;
}

.o-pane--small {
  padding-right: ($spacing-small / 2);
  padding-left: ($spacing-small / 2); 
}

.o-pane--bleed {
  @include breakpoint($mobilexxs) {
    padding-left: 0;
    padding-right: 0;
  }
}


/**
 * A highlighted pane seperates itself from sibling panes with a background color and borders.
 */

.o-pane--highlight {
  background-color: $color-white;

  border-top: 1px solid $color-border;
  border-bottom: 1px solid $color-border;
}

.o-pane--secondary {
	background-color: $color-secondary;	
}

.o-pane--gradient {
	background: #00558a;
	background: -moz-linear-gradient(left,  #00558a 0%, #00a6d7 100%);
	background: -webkit-linear-gradient(left,  #00558a 0%,#00a6d7 100%);
	background: linear-gradient(to right,  #00558a 0%,#00a6d7 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00558a', endColorstr='#00a6d7',GradientType=1 );
}

.o-pane--fromtop {
  transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 0;
  display: block;
}
.o-pane--fromright {
  transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  transform: translateX(-400px);
  margin-top: 0;
  right: -400px;
  display: block;

  @include breakpoint($mobilexxs) {
    transform: translateX(-100%);
    right: -100%;
  }
}

.o-pane--hidden {
  display: none;
}

.o-pane--hidden.o-pane--fromtop {
  margin-top: -258px;
  display: block;
}
.o-pane--hidden.o-pane--fromright {
  transform: translateX(0);
  display: block;
}

.o-pane--border {
  border-bottom: 1px $color-border solid;
}



/**
 * The inner pane provides a buffer between the top and bottom of the pane
 * and its contents. The inner padding contracts `$spacing-small` to take into
 * account the mandatory padding on any element within the inner.
 */

.o-pane__inner {
  padding-top: $spacing-huge;
  padding-bottom: $spacing-huge;
}

.o-pane__container {
  padding: $spacing-small;
}



/**
 * IE9 Layout fixes
 */

.ie9 .o-pane--hidden.o-pane--fromright {
  right: -400px;
}

.ie9 .o-pane--fromright {
  right: 0;
}
