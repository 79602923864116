/*! Tools - Mixins */

/**
 * The container mixin defines the max width of a content container and
 * auto centers it.
 */

 @mixin container($width: $container) {
 	max-width: $width;

 	margin-left: auto;
 	margin-right: auto;
 }

/**
 * Standard clearfix hack used for clearing floats contained in a block level
 * element as by (http://blog.teamtreehouse.com/a-better-clearfix-with-sass).
 */

 @mixin clearfix {

 	&:after {
 		content: '';

 		display: table;

 		clear: both;
 	}

 }


/**
 * A quick icon font mixin which utilizes FontAwesome to generate unicode icons in a
 * before and/or after psuedo element selector using the content attribute.
 */

@mixin fontawesome($icon, $font-size: inherit, $color: inherit) {
  content: $icon;

  font-family: $font-family-icon;
  font-size: $font-size;
  color: $color;

}