/*! Generic - Reset */

/** Browser defaults reset based on normalize.css v4.1.1 by Necolas
 * (github.com/necolas/normalize.css)
 * - Latest update April 12 2016
 */

/**
 * Remove all margins and paddings in all browsers.
 */

* {
  margin: 0;
  padding: 0;
}

/**
 * Box-sizing reset (https://css-tricks.com/box-sizing/).
 * You can use content-box or padding-box (where supported) at will,
 * without worrying about a universal selector overriding your CSS.
 */

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  
  box-sizing: inherit;
}

/**
 * 1. Box sizing preserves the actual visible/rendered width and height
 *    of an element despite it having a padding or border.
 * 2. Set height of html 100% of window height.
 * 3. Optimize text rendering for all browsers.
 * 4. Prevent adjustment of font size after orientation changes in IE
 *    and iOS.
 */

html {
  box-sizing: border-box; /* 1 */

  height: 100%; /* 2 */

  font-size: 100%; /* 3 */

  text-rendering: optimizeLegibility; /* 3 */

  -webkit-font-smoothing: antialiased; /* 3 */
  -moz-font-smoothing: grayscale; /* 3 */

  text-size-adjust: 100%; /* 4 */
}

/**
 * 1. Set position relative to allow positioning of modals, notifications,
 *    tooltips and fixed navigation.
 * 2. Set min height of body 100% to allow dynamic content.
 */

body {
  position: relative; /* 1 */

  min-height: 100%; /* 2 */
}

/* HTML5 display definitions
   ------------------------------------------------------------------------- */

/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE and Firefox.
 * 2. Add the correct display in IE.
 */

article,
aside,
details, /* 1 */
figcaption,
figure,
footer,
header,
main, /* 2 */
menu,
nav,
section,
summary /* 1 */ {
  display: block;
}

/**
 * Add the correct display in IE 9-.
 */

audio,
canvas,
progress,
video {
  display: inline-block;
}

/**
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Add the correct display in iOS 4-7.
 */

audio:not([controls]) {
  display: none;

  height: 0;
}

/**
 * Add the correct vertical alignment in Chrome, Firefox and Opera.
 */

progress {
  vertical-align: baseline;
}

/**
 * Add the correct display in IE 10-.
 * 1. Add the correct display in IE.
 */

template, /* 1 */
[hidden] {
  display: none;
}

/* Anchors
   ------------------------------------------------------------------------- */

/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */

a {
  -webkit-text-decoration-skip: objects; /* 2 */

  background-color: transparent; /* 1 */
}

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers.
 */

a:active,
a:hover {
  outline-width: 0;
}

/* Text node semantics
   ------------------------------------------------------------------------- */

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera and Safari.
 */

abbr[title] {
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */

  border-bottom: 0; /* 1 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * Add the correct font style in Android 4.3-.
 */

dfn {
  font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */

mark {
  color: #000;

  background-color: #ff0;
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height
 * in all browsers.
 */

sub,
sup {
  position: relative;

  font-size: 75%;

  line-height: 0;

  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ------------------------------------------------------------------------- */

/**
 * Remove the border on images inside links in IE 10-.
 */

img {
  border-style: none;
}

/** 
 * Hide the overflow in IE.
 */

svg:not(:root) {
  overflow: hidden;
}

/* Grouping content
   ------------------------------------------------------------------------- */

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
pre,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct margin in IE 8.
 */

figure {
  margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */

hr {
  box-sizing: content-box; /* 1 */

  height: 0; /* 1 */

  overflow: visible; /* 2 */
}

/* Forms
   ------------------------------------------------------------------------- */

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Change font properties to `inherit` in all browsers.
 */

button,
input,
select,
textarea {
  margin: 0; /* 1 */

  font: inherit; /* 2 */
}

/**
 * Restore the font weight unset by the previous rule.
 */

optgroup {
  font-weight: bold;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input { /* 1 */
  overflow: hidden;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select { /* 1 */
  text-transform: none;
}

/**
 * Remove border radius in Webkit browsers on Safari and Chrome iOS
 */

input,
textarea,
select {
  border: 0;
  border-radius: 0;

  -webkit-appearance: none;
}

/**
 * Remove default `box-sizing` on `input[type="search"]` in Safari on iOS.
 */

input[type='search'] {
  box-sizing: border-box;
}

/**
 * 1. Prevent a Webkit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */

button,
html [type="button"], /* 1 */
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; /* 2 */
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;

  border-style: none;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */

legend {
  display: table; /* 1 */

  box-sizing: border-box; /* 1 */

  max-width: 100%; /* 1 */

  padding: 0; /* 3 */

  color: inherit; /* 2 */

  white-space: normal; /* 1 */
}

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Allow only vertical resizing of text areas.
 */

textarea {
  overflow: auto; /* 1 */

  resize: vertical; /* 2 */
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding IE 10-.
 */

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */

  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */

  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on OS X.
 */

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Correct the text style of placeholders in Chrome, Edge and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;

  opacity: 0.54;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */

  font: inherit; /* 2 */
}

/* Selection
   ------------------------------------------------------------------------- */

/*
 * Remove text-shadow in selection highlight:
 * (https://twitter.com/miketaylr/status/12228805301).
 * These selection rule sets have to be separate (when not using postcss).
 */

::selection {
  text-shadow: none;
}
