/* ==========================================================================
   components.breadcrumbs
   ========================================================================== */

.c-breadcrumb {
	position: relative;
	margin-bottom: $spacing-default;

	@include breakpoint($mobilexxs) {
		margin: 0 15px 40px 15px;

		.c-button {
			margin-bottom: 15px;
		}
	}
}

.c-breadcrumb--float {
	position: absolute;
	bottom: 30px;

	margin-bottom: 0;

	@include breakpoint($mobilexxs) {
		bottom: 5px;
		left: 0;
		right: 0;

		margin: 0 15px;
	}
}

.c-breadcrumb--inBanner {
	z-index: $z-index-8;
	bottom: 30px;
	left: 30px;

	margin-bottom: 0;

	@include breakpoint($mobilexxs) {
		margin: 0 15px 0 15px;
		left: 0;

		.c-button {
			margin-bottom: 0;
		}
	}
}