/*! Main */

/**
 * Main import structure and file naming according to ITCSS
 * by Harry Roberts (http://csswizardry.com).
 * - It’s important not to output any CSS in the first 2 layers.
 */

 /* Vendors
   ------------------------------------------------------------------------- */
@import '../bower_components/font-awesome/scss/font-awesome';
@import "../bower_components/breakpoint-sass/stylesheets/breakpoint";


/* Settings
   ------------------------------------------------------------------------- */

/**
 * Global settings, variables and configuration.
 */

@import 'settings.colors';
@import 'settings.global';

/* Tools
   ------------------------------------------------------------------------- */

/**
 * Globally used mixins and functions.
 */

@import 'tools.mixins';

/* Generic
   ------------------------------------------------------------------------- */

/**
 * Reset and/or normalize styles.
 */

@import 'generic.reset';
@import 'generic.base';

/* Elements
   ------------------------------------------------------------------------- */

/**
 * Styling for bare HTML elements.
 */

@import 'elements.headings';
@import 'elements.anchors';
@import 'elements.lists';
@import 'elements.media';
@import 'elements.tables';
@import 'elements.forms';
@import 'elements.hr';
@import 'elements.fonts';
@import 'elements.icons';
@import 'elements.blockquote';
@import 'elements.paragraph';

/* Objects
   ------------------------------------------------------------------------- */

/**
 * Objects – Class-based selectors which define undecorated design patterns.
 */

@import 'objects.effects';
@import 'objects.layout';
@import 'objects.pane';
@import 'objects.wrappers';
@import 'objects.containers';

/* Components
   ------------------------------------------------------------------------- */

/**
 * Specific UI components.
 */

@import 'components.main-header';
@import 'components.main-nav';
@import 'components.main-search';
@import 'components.main-sidebar';
@import 'components.main-footer';
@import 'components.hero';
@import 'components.logo';
@import 'components.avatar';
@import 'components.cards';
@import 'components.buttons';
@import 'components.forms';
@import 'components.inputs';
@import 'components.selects';
@import 'components.article';
@import 'components.icons';
@import 'components.breadcrumb';
@import 'components.isbn-block';
@import 'components.carousel';
@import 'components.clients';
@import 'components.contact';
@import 'components.google-maps';
@import 'components.quicklinks';
@import 'components.language';
@import 'components.page-navigation';
@import 'components.pagination';
@import 'components.maps';
@import 'components.tabs';
@import 'components.socials';
@import 'components.tiles';
@import 'components.date';
@import 'components.quote';
@import 'components.video';
@import 'components.checkbox';
@import 'components.radios';
@import 'components.faq';


/* Trumps
   ------------------------------------------------------------------------- */

/**
 * Utilities and helper classes.
 */

 @import 'trumps.helpers';

 @import 'ie8';
