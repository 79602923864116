/* ==========================================================================
   components.main-sidebar
   ========================================================================== */

.c-main-sidebar {
	width: 400px;
	height: 100%;
	min-height: 100%;

	background: $color-white;

	box-shadow: 0px 2px 10px 2px rgba(0,0,0,0.10);

	position: fixed;
	top: 0;
	bottom:0;
	z-index: $z-index-9;

	overflow-y: scroll;

	@include breakpoint($mobilexxs) {
		width: 100%;

		box-shadow: none;
	}

}

.c-main-sidebar__header {
	height: 100px; //same height as topbar

	position: relative;
}

.c-main-sidebar__container {
	padding: $spacing-default;
}


/**
 * Main sidebar toggle
 */

.c-main-sidebar__toggle {
	position: absolute;
	top: 39px;
	right: 40px;

	&:hover {
		cursor: pointer;
	}
}

.c-main-sidebar__toggle .line {
	width: 25px;
	height: 3px;

	background-color: $color-secondary;

	display: block;

	margin: 5px auto;

	// transition: all 0.3s ease-in-out;
}

.c-main-sidebar__toggle .line:nth-child(2) {
  opacity: 0;
}

.c-main-sidebar__toggle .line:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.c-main-sidebar__toggle .line:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}


/**
 * Main sidebar navigation
 */

.c-main-sidebar__nav {
	border-bottom: 1px $color-border solid;

	padding-bottom: $spacing-small;
}

.c-main-sidebar__nav ul {
	margin: 0;
	padding: 0;
}

.c-main-sidebar__nav li a {
	font-family: $font-family-bold;
	font-size: $font-size-m;

	color: $color-primary;

	padding: $spacing-small 0;

	display: block;

	&:hover {
		color: $color-secondary;
	}
}



/**
 * Main sidebar utilities
*/

.c-main-sidebar__utilities {
	padding-top: $spacing-small;
}

.c-main-sidebar__utilities ul {
	
}

.c-main-sidebar__utilities ul li {
	margin-left: $spacing-small;

	display: inline-block;

	@include breakpoint($mobilexxs) {
		margin-left: 10px;
	}
}

.c-main-sidebar__utilities ul li:first-child {
	margin-left: 0;
}

.c-main-sidebar__utilities ul li a {
	color: $color-primary;

	&:hover {
		color: $color-secondary;
	}
}


/**
 * Main sidebar socials
*/

.c-main-sidebar__socials {
	width: 400px;

	background-color: $color-white;

	position: fixed;
	bottom: 0;
	right: 0;
	z-index: $z-index-9;

	display: none;

	@include breakpoint($mobilexxs) {
		width: 100%;
		position: static;
	}
}

.c-main-sidebar__socials ul {
	@include clearfix;

	border-top: 1px $color-border solid;
}

.c-main-sidebar__socials ul li:first-child a {
	border-left: 0;
}

.c-main-sidebar__socials ul li a {
	width: 33.333%;

	float: left;

	padding: 20px 0 16px 0;

	text-align: center;

	border-left: 1px $color-border solid;
}
