/* ==========================================================================
   components.selects
   ========================================================================== */

.c-select {
  position: relative; 
}

/**
 * Sort icon, hide on all browsers except webkit.
 */

.c-select:after {
  content: '\f107'; // fa-angle-down

  display: none;

  position: absolute;
  right: $spacing-small;
  top: 50%;
  z-index: 2;

  transform: translateY(-50%);

  pointer-events: none;

  font-family: $font-family-icon;
  color: $color-body;
}

/**
 * Only display select icon in webkit browsers.
 */

.c-select:not(*:root):after {
  display: block;
}


.form__field .select {
  position: relative; 
}

.form__field .select:after {
  content: '\f107'; // fa-angle-down

  display: none;

  position: absolute;
  right: $spacing-small;
  top: 50%;
  z-index: 2;

  transform: translateY(-50%);

  pointer-events: none;

  font-family: $font-family-icon;
  color: $color-body;
}

.form__field .select:not(*:root):after {
  display: block;
}

.form__field .select select {
  min-width: 400px;
  padding: 0.5em 1em;

  vertical-align: middle;
  color: $color-body;

  background-color: $color-white;

  border: 1px $color-border solid;
  border-radius: $border-radius;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  @include breakpoint($mobilexxs) {
    min-width: 100%;
  }
}