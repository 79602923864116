/*! Settings - Global */

/**
 * The max width of a container.
 */

$container:               1200px;

/**
 * The amount of columns that span a container. All grid calculations are
 * based on this number.
 */

$columns:                 12;

/* Paths
   ------------------------------------------------------------------------- */

/**
 * Relative path variables for images and fonts.
 */

$path-img:                '../img/';

/* Typography
   ------------------------------------------------------------------------- */

/* Family */

$font-family-blond:		'FaktPro-Blond', Helvetica, Arial, Verdana, sans-serif;
$font-family-normal:	'FaktPro-Normal', Helvetica, Arial, Verdana, sans-serif;
$font-family-bold:		'FaktPro-Bold', Helvetica, Arial, Verdana, sans-serif;

$font-family-icon:		'FontAwesome';

/* Size */

$font-size-default:       	16px;

$font-size-h1:				3.000em;
$font-size-h2:				2.4rem;
$font-size-h3:				1.500em;
$font-size-h4:				1.125em;
$font-size-h5:				1.2em;
$font-size-h6:				1em;

$font-size-l:				4.000em;
$font-size-m:				1.125em;
$font-size-s:				0.875em;

/* Baseline */

$font-baseline:				1.5em;

/* Weight */

$font-weight-normal:		400;
$font-weight-bold:			700;

/* Spacings
   ------------------------------------------------------------------------- */

/**
 * Spacings are based on and multiplied by the defined
 * vertical rythm.
 */

// Vertical rythm

$rhythm:                  30px;

// Spacing multipliers

$spacing-small:           ($rhythm/2);
$spacing-default:         $rhythm;
$spacing-large:           ($rhythm+($rhythm/2));
$spacing-huge:            ($rhythm*2);

/**
 * Borders
 */

/* Default border radius when present. */

$border-radius:           3px;

/**
 * Z-index
 */

// Scales

$z-index-1:               111;
$z-index-2:               222;
$z-index-3:               333;
$z-index-4:               444;
$z-index-5:               555;
$z-index-6:               666;
$z-index-7:               777;
$z-index-8:               888;
$z-index-9:               999;
$z-index-10:              -1;



/**
 * Breakpoints
 */

$navhide: max-width 1040px;
$small-desktop: max-width 1480px;
$tablet: 600px 900px;
$mobilexxs: max-width 600px;
$mobilexxxs: max-width 480px;
