/* ==========================================================================
   objects.effects
   ========================================================================== */


/**
 * Animate effect on input labels
 */

.e-t--up {
	position: absolute;
	top: $spacing-small;
	left: $spacing-small;
}

.e-t--focus:focus > .e-t--up {
	top: 0;
	left: 0;
}



/**
 * Animate grayscale on hover
 */

.e-t--grayscale {
	filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
	filter: gray;
	-webkit-filter: grayscale(100%);

	cursor: pointer;

	transition: all .3s;
}

.e-t--grayscale:hover {
	filter: none;
	-webkit-filter: grayscale(0);
}


/**
 * Animate image scale
 */

.e-t--zoom {
	transition: all .3s;
}

.e-t--zoom:hover {
	transform: scale(1.1);
}