/* ==========================================================================
   components.contact
   ========================================================================== */

.c-contact {
	width: 100%;

	background-color: $color-white;

	border: 1px $color-border solid;

	padding: 50px;
	margin-bottom: 60px;

	@include breakpoint($mobilexxs) {
		padding: 30px;
	}
}

.c-contact__inner {
	display: flex;
	justify-content: center;
	align-content: center;

	@include breakpoint($mobilexxs) {
		display: block;	
	}
}

.c-contact__image {
	flex-basis: 175px;
	margin-right: 60px;

	img {
		width: 175px;
		height: 175px;
		border-radius: 50%;
	}
}

.c-contact__container {
	width: 500px;
	flex-basis: 500px;

	@include breakpoint($mobilexxs) {
		width: 100%;
		margin-top: 30px;

		a {
			display: block;
		}
	}
}

#contactForm .o-layout__item-6 {
	@include breakpoint($tablet) {
		flex-basis: 58.33333%;
		max-width: 58.33333%;
	}
}


.ie9 .c-contact__inner {
	@include clearfix;
	display: block;
}

.ie9 .c-contact__image {
	float: left;
}

.ie9 .c-contact__container {
	float: left;
}