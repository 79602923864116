/* ==========================================================================
   elements.media
   ========================================================================== */

/**
 * Default img width to 100% for responsive.
 */

img {
  width: 100%;
}

.u-img-pull {
	margin: -80px 0 30px 0;
}
