/* ==========================================================================
   components.maps
   ========================================================================== */

.c-maps {
	width: 100%;
	height: 350px;

	position: relative;

	@include breakpoint($mobilexxs) {
		height: auto;
	}
}

.c-maps__content {
	width: 300px;

	position: absolute;
	right: 0;
	left: 0;
	bottom: 40px;

	margin: 0 auto;

	@include breakpoint($mobilexxs) {
		width: 100%;

		position: relative;
		bottom: 0;
	}
}