/* ==========================================================================
   elements.blockquote
   ========================================================================== */

blockquote {
	font-size: 1.500em;
	line-height: 1.3;
	
  	display: block;
}
