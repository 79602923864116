/* ==========================================================================
   components.tiles
   ========================================================================== */

.c-tile {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	position: relative;
}

.c-tile__container {
	width: 100%;

	position: absolute;
	bottom: 40px;
	z-index: $z-index-8;

	padding-left: 15px;
	padding-right: 15px;
}

.c-tile__image {
	width: 100%;
	height: 100%;

	transform: scale(1);

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: $z-index-6;

	background-size: cover;
	background-position: center center;

	transition: transform 0.5s ease-in-out
}

.c-tile__link {
	width: 100%;
	height: 100%;

	position: relative;

	display: block;

	overflow: hidden;
}

.c-tile__link:hover
.c-tile__image {
	transform: scale(1.1);
}



/**
 * Add this modifier when a tile has text over image
 */

.c-tile__image--overlay::before {
	content: "";

	width: 100%;
	height: 50%;

	background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 75%);
	background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 75%);
	background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 75%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );

	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;

	z-index: $z-index-7;
}


/**
 * Puts content on top over overlay
 */

.c-tile--overlay .c-tile__link {
	z-index: $z-index-8;
}