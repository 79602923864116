/* ==========================================================================
   components.video
   ========================================================================== */

.c-video {
	position: relative;
    padding-bottom: 65.25%;
    padding-top: 30px;
    height: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.c-video iframe {
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}