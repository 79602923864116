/* ==========================================================================
   components.tabs
   ========================================================================== */

.c-tabs {
	
}


/**
 * Tabs navigation
 */

.c-tabs__nav {
	@include clearfix;

	border-bottom: 1px $color-border solid;
}

.c-tabs__nav li {
	list-style: none;

	float: left;

	border: 1px $color-border solid;

	margin: 0 0 -1px -1px !important;
	padding-left: 0 !important;
}

.c-tabs__nav li:before {
	display: none;
}

.c-tabs__nav li:first-child {
	z-index: $z-index-5;
}

.c-tabs__nav li.is-active a {
	font-family: $font-family-bold;

	color: $color-primary;
	background-color: $color-white;

	// box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);

	top: 1px;
}

.c-tabs__nav li a {
	display: block;

	color: $color-body;
	background-color: $color-background-body;

	padding: 1.9rem 3rem;

	position: relative;
	z-index: $z-index-4;
}


/**
 * Tabs content
 */

.c-tabs__content {
	// padding: $spacing-default;
}

.c-tabs__panel {
	display: none;
}

.c-tabs__panel.is-active {
	display: block;
}