/* ==========================================================================
   components.avatar
   ========================================================================== */

.c-avatar {
	border-radius: 100%;
	overflow: hidden;
}

.c-avatar--l {
	width: 175px;
	height: 175px;
}