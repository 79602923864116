/* ==========================================================================
   components.carrousel
   ========================================================================== */

.c-carousel {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 30px;
}

.c-carousel .c-card {
  width: 350px;
  position: absolute;
  top: 0;
}

.c-carousel__inner {
  display: block;
  transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

.c-carousel--books .c-card {
  width: 204px;
}

.c-carousel__button {
  width: 100px;
  text-align: left;
  background-color: #faf9f8;
  box-shadow: 5px 0px 10px 0px rgba(213, 213, 213, 0.75);
  // background: -moz-linear-gradient(left,  rgba(250,249,248,1) 0%, rgba(250,249,248,1) 30%, rgba(250,249,248,0) 100%);
  // background: -webkit-linear-gradient(left,  rgba(250,249,248,1) 0%,rgba(250,249,248,1) 30%,rgba(250,249,248,0) 100%);
  // background: linear-gradient(to right,  rgba(250,249,248,1) 0%,rgba(250,249,248,1) 30%,rgba(250,249,248,0) 100%);
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#faf9f8', endColorstr='#00faf9f8',GradientType=1 );
  color: $color-primary;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  // opacity: 0.9;
}

.c-carousel__button--next {
  left: auto;
  right: 0;
  text-align: right;
  box-shadow: -5px 0px 10px 0px rgba(213, 213, 213, 0.75);
}

.c-carousel__circle {
  width: 50px;
  height: 50px;
  background-color: $color-white;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding-top: 15px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 25px;
  margin-top: -25px;
  cursor: pointer;
  transition: all .3s;
  &:hover {
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
  }
}

.c-carousel__indicator {
  width: 350px;
  margin: 20px auto 0;
  position: relative;
  background-color: $color-placeholder;
  overflow: hidden;
  height: 5px;
}

.c-carousel__indicatorPoint,
.c-carousel__indicatorPointAdd {
  position: absolute;
  transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  left: 0;
  width: 30px;
  background-color: $color-secondary;
  height: 5px;
  min-width: 5px;
}

.c-bookchart {
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.c-bookchart__items {
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.c-bookchart__items>* {
  display: block;
  position: absolute;
}

.c-book__image {
  display: block;
}
.c-book__title {
  display: block;
  text-align: center;
  font-size: 14px;
  color: $color-body;
  margin-top: 10px;
}
.c-bookchart__prev,
.c-bookchart__next {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50px;
  left: 20px;
  top: 50%;
  margin-top: -50px;
  .c-carousel__circle {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 0;
  }
}

.c-bookchart__next {
  right: 20px;
  left: auto;
}

.c-book__image:after {
  content: " ";
  position: relative;
  display: block;
  width: 100%;
  height: 8px;
  z-index: 0;
  border-top: 8px solid #b6b6b6;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  box-sizing: border-box;
}
