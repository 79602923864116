/* ==========================================================================
   components.language
   ========================================================================== */

.c-language-toggle {
	font-family: $font-family-normal;

	color: $color-primary;

	.fa {
		margin-left: 5px;
	}

	@include breakpoint($mobilexxs) {
		display: none;
	}
}