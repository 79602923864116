/* ==========================================================================
   elements.paragraph
   ========================================================================== */

p {
	// margin: 5px 0;
}

strong {
	font-family: $font-family-bold;
}