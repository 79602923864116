/* ==========================================================================
   components.quicklinks
   ========================================================================== */

.c-quicklinks ul {
	margin: 0;
	padding: 0;
}

.c-quicklinks ul li {
	list-style: none;
}

.c-quicklinks ul li a {
	color: $color-white;

	padding: 5px 0;

	display: block;
}

.c-quicklinks ul li:last-child a  {
	padding-bottom: 0;
}