/* ==========================================================================
   components.main-search
   ========================================================================== */

.c-main-search {
	padding: 100px 0;
}

.c-main-search__close {
	position: absolute;
	top: 30px;
	right: 30px;
}

.c-main-search__submit {
	position: absolute;
	right: 0;
	bottom: 0;

	background: transparent;
	color: $color-white;

	border: none;

	padding: 0 $spacing-small $spacing-small 0;

	cursor: pointer;
}