/* ==========================================================================
   components.icons
   ========================================================================== */

.c-icon {
	width: 40px;
	height: 40px;

	margin: 0 auto 20px auto;
}

.c-icon--m {
	width: 80px;
	height: 80px;
}

.c-icon--outline {
	border: 2px $color-white solid;
	border-radius: 50%;
	text-align: center;
	padding-top: 7px;
}

.c-icon--secondary {
	border-color: $color-secondary;
}