/* ==========================================================================
   components.page-navigation
   ========================================================================== */

.c-page-navigation {
	display: flex;

	flex: 0 1 auto;
	flex-direction: row;
	flex-wrap: wrap;

	border-top: 1px $color-border solid;

	position: relative;
	overflow: hidden;

	@include breakpoint($mobilexxs) {
		overflow: visible;
	}
}

.c-page-navigation--top {
	border-top: 0;
	border-bottom: 1px $color-border solid;

	@include breakpoint($mobilexxs) {
		display: none;
	}
}

.c-page-navigation__item {
	flex-basis: 25%;

    max-width: 25%;

	border-right: 1px $color-border solid;

	color: $color-body;

	text-align: center;

	padding: 38px 0;

	display: block;

	@include breakpoint($mobilexxs) {
		flex-basis: 50%;

	    max-width: 50%;

	    border-bottom: 1px $color-border solid;
	}
}

.c-page-navigation__item.is-last {
	border-right: 0;
}

.c-page-navigation__item:hover {
	color: $color-secondary;
}


/**
 * IE9 Layout fixes
 */

.ie9 .c-page-navigation {
  @include clearfix;
  display: block;
}

.ie9 .c-page-navigation__item {
	width: 25%;
	display: block;
	float: left;
}