/* ==========================================================================
   components.main-header
   ========================================================================== */

.c-main-header {
	width: 100%;
	height: 100px;

	padding: $spacing-small 0;

	background-color: $color-white;

	border-bottom: 1px $color-border solid;

	// position: fixed;
	// top: 0;
	// left: 0;
	// right:0;
	// z-index: $z-index-8;

	// transition: top 0.2s ease-in-out;
}

// .c-main-header.is-nav-up {
// 	top: -100px;
// }

.c-main-header__link {
	padding: $spacing-small $spacing-default;

	display: block;

	border-left: 1px $color-border solid;

	@include breakpoint($mobilexxs) {
		padding: $spacing-small 20px;
	}
}