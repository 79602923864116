.c-faq {
 }

.c-faq__block {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-bottom: 30px;
  padding: 20px 0;
  p {
    margin: 0;
  }
}

.c-faq__block + .c-faq__category {
  margin-top: 60px;
}

.c-faq__category {
  margin-bottom: 30px;
}
 
.c-faq__question { 
  cursor: pointer;
}

.c-faq__answer {
  padding-top: 0;
  overflow: hidden;
  transition: all 300ms ease;
  opacity: 0;
}

.c-faq__answer.js-active {
  padding-top: 10px;
  opacity: 1;
}
