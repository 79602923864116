/* ==========================================================================
   objects.layout
   ========================================================================== */

/**
 * Layout is a flex based display model element.
 */

.o-layout {
  display: flex;

  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;

  @include breakpoint($mobilexxs) {
    width: 100%;
    max-width: 100%;

    display: block;
  }
}

/**
 * Keeps working on mobile
 */

.o-layout--mobile {
  display: flex !important;

  width: auto !important;
  max-width: auto !important;
}

/**
 * Align content in the middle of their layout items.
 */

.o-layout--center {
  align-items: center;
}


/**
 * Justifies items left and right 
 */

.o-layout--justify {
  justify-content: space-between;
}

.o-layout--justify-center {
  justify-content: center;
}



/**
 * Align content at the bottom of their layout items.
 */

.o-layout--end {
  align-items: flex-end;
}

/**
 * Change order
 */

.o-layout--image-right {
  order: 2;
}




/**
 * Equal heights layout hack.
 */

.o-layout--stretch > [class*="o-layout__item-"] {
  display: flex;
}

.o-layout--stretch > [class*="o-layout__item-"] > * {
  width: 100%;
}

/**
 * Default layout items.
 * By default, layout items don't have any gutters.
 * Gutters can be provided by using a `.container` child within a layout item.
 */

@for $i from 1 through $columns {

  .o-layout__item-#{$i} {
    position: relative; // experimental

    padding: $spacing-small;

    flex-basis: (100% * ($i / $columns));

    max-width: (100% * ($i / $columns));

    @include breakpoint($mobilexxs) {
      width: 100%;
      max-width: 100%;
      
      display: block;

      padding: $spacing-small 0;
    }
  }

}

.o-layout__item--bleed {
  padding: 0;
}

.o-layout__item--left-bleed {
  padding-left: 0;
}

.o-layout__item--right-bleed {
  padding-right: 0;
}


.o-layout__md-12 .o-layout__item-6 {
  @include breakpoint($tablet) {
    flex-basis: 100%;

    max-width: 100%;

    padding: 0;
    
    &:first-child {
      margin-bottom: 30px;
    }
  }
}

.o-wrapper--wide {
  @include breakpoint($small-desktop) {
    .o-layout__item-3 {
      width: 50%;
      -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
      max-width: 50%;
    }
  }
  @include breakpoint($tablet) {
    .o-layout__item-full {
      width: 100%;
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      max-width: 100%;
    }
  }
  @include breakpoint($mobilexxs) {
    .o-layout__item-full {
      width: 100%;
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}
.o-layout__item-full {
  @include breakpoint($tablet) {
    flex-basis: 100%;

    max-width: 100%;
  }
  
}


.o-layout__mobile {
  @include breakpoint($tablet) {
    display: flex;
  }
}

.o-layout__mobile-split {
  @include breakpoint($mobilexxs) {
    max-width: 50%;
    flex-basis: 50%;
    padding: 15px;
  }
}

/**
 * IE9 Layout fixes
 */

.ie9 .o-layout {
  @include clearfix;
  display: block;
}

@for $i from 1 through $columns {

  .ie9 .o-layout__item-#{$i} {
    float: left;
    width: (100% * ($i / $columns));
  }

}

.ie9 .o-layout--justify div:first-child {
  float: left;
}

.ie9 .o-layout--justify div {
  float: right;
}
