/* ==========================================================================
   elements.tables
   ========================================================================== */

/**
 * Default border collapse on tables and width 100%.
 */

table {
  width: 100%;

  border-collapse: collapse;
}
