/* ==========================================================================
   elements.hr
   ========================================================================== */

/*
 * A better looking default horizontal rule according to
 * (https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css).
 */

hr {
  display: block;

  height: 1px;

  margin: 0;
  padding: 0;

  background-color: $color-border;

  border: 0;
}
