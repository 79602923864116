/* ==========================================================================
   objects.wrapper
   ========================================================================== */

/**
 * Wrappers act as containers. Modified wrappers can have different max-width
 * using the container @mixin which can be referenced at (_tools.mixins).
 */

.o-wrapper {
  @include container();
}

/**
 * Wrapper modifiers can be invoked directly to reduce HTML bloat.
 */

.o-wrapper--slim {
  @include container(990px);
}

.o-wrapper--skinny {
  @include container(790px);
}

.o-wrapper--wide {
  @include container(1440px);
}

/**
 * Wrapper with negative margin for homepage
 */

.o-wrapper--pull {
	margin-top: -205px;

	@include breakpoint($mobilexxs) {
		margin-top: 0;
	}
}

.o-wrapper--lift {
	margin-top: -205px;

	@include breakpoint($mobilexxs) {
		margin-top: -158px;
	}
}
