/* ==========================================================================
   elements.headings
   ========================================================================== */

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-family-bold;
  	line-height: 1.1em;

  	color: $color-primary;

  	margin-bottom: $spacing-small;
}

h1,
.h1 {  
	font-size: $font-size-h1;

	@include breakpoint($mobilexxs){
		font-size: 2.250em !important;
		word-break: break-word;
	}
}

h2 {
	font-size: $font-size-h2;

	&.h2 {
		font-size: 3.6rem;
	}
}

h3 {
	font-size: $font-size-h3;
}

h4 {
	font-size: $font-size-h4;
}

h5 {
	font-size: $font-size-h5;
}

h6 {
	font-size: $font-size-h6;
}
