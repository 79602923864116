/* ==========================================================================
   components.cards
   ========================================================================== */

.c-card {
	background-color: $color-white;

	border: 1px $color-border solid;

	@include breakpoint($mobilexxs) {
		&.u-text-center {
			text-align: left !important;
		}
	}
}

.c-card__container {
	padding: 40px;

	@include breakpoint($mobilexxs) {
		padding: 40px 20px;
	}
}

.c-card__image {
	overflow: hidden;
}

.c-card__container--large {
	padding: 70px 50px;

	@include breakpoint($mobilexxs) {
		padding: 30px;
	}
}

.c-card__container--slim {
	padding: 30px;
}

.c-card__container--small {
	padding: $spacing-small;
}

.c-card__container--none {
	padding: 0;
}

.c-card__icon {
	margin-bottom: 30px;

	img {
		width: 120px;
	}
}


/**
 * Modifiers for cards
 */

.c-card--secondary {
	background-color: $color-secondary;

	border: 0;
}

.c-card--primary {
	background-color: $color-primary;

	border: 0;
}

.c-card--facts {
	p,
	.u-text-h1 {
		color: $color-white;
		line-height: 1.1;
	}
}

.c-card--facts.c-card--secondary div {
	color: $color-primary !important;
}

.c-card--horizontal {
	padding-top: $spacing-default;
	padding-bottom: $spacing-default;

	border: 0;
	border-bottom: 1px $color-border solid;
}

.c-card__image--small {
	width: 150px;
	height: 150px;
}

.c-card__image--right {
	text-align: right;

	@include breakpoint($mobilexxs) {
		text-align: left;
	}
}


/**
 * Add shadow to a card instead of border.
 */

.c-card--shadow {
	border: 0;

	-webkit-box-shadow: 0px 2px 10px 2px rgba(0,0,0,0.10);
	-moz-box-shadow: 0px 2px 10px 2px rgba(0,0,0,0.10);
	box-shadow: 0px 2px 10px 2px rgba(0,0,0,0.10);
}
