/* ==========================================================================
   components.socials
   ========================================================================== */

.c-socials {
	width: 75%;
	height: 124px;

	background-color: $color-background-body;
	border-right: 1px $color-border solid;

	position: absolute;
	left: 0;
	bottom: 0;

	padding: 37px 0 0 37px;

	overflow: hidden;
	visibility: hidden;

	transform: translateX(-100%);

	transition: transform .3s;

	@include breakpoint($mobilexxs) {
		width: 100%;
		height: auto;
		
		padding: 30px;
		a {
			margin-bottom: 10px;
		}
	}
}

.c-socials.is-open {
	transform: translateX(0px);
	visibility: visible;
}