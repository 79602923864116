/* ==========================================================================
   components.checkbox
   ========================================================================== */

.checkbox[type="checkbox"],
.checkbox[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}

.checkbox[type="checkbox"] + label,
.checkbox[type="checkbox"]:checked + label {
  position: relative;

  padding: 7px 0 0 32px;
  
  cursor: pointer;
}

.checkbox[type="checkbox"] + label:before,
.checkbox[type="checkbox"]:checked + label:before {
  content: '';

  width: 23px;
  height: 23px;

  position: absolute;
  left: 0; 
  top: 2px;

  
  border: 1px solid $color-border;
  border-radius: $border-radius;

  background-color: $color-white;
}

.checkbox[type="checkbox"] + label:after,
.checkbox[type="checkbox"]:checked + label:after {
  content: '\f00c';

  font-family: $font-family-icon;

  position: absolute;
  top: 7px;
  left: 4px;

  font-size: 16px;
  line-height: 0.8;

  color: $color-secondary;

  transition: all .2s;
}

.checkbox[type="checkbox"] + label:after {
  opacity: 0;
  transform: scale(0);
}

.checkbox[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}