/* ==========================================================================
   components.main-footer
   ========================================================================== */

.c-main-footer {
	padding: 58px 0;

	@include breakpoint($mobilexxs) {
		padding: $spacing-default 0;
	}
}

.c-main-footer__list {
	margin: 0;
}

.c-main-footer__list li {
	display: inline-block;

	list-style: none;

	margin-left: 100px;

	@include breakpoint($tablet) {
		margin-left: 50px;
	}

	@include breakpoint($mobilexxs) {
		display: block;

		margin-left: 0;
	}
}

.c-main-footer__list li:first-child {
	margin-left: 0;
}

.c-main-footer__list li a {
	font-family: $font-family-normal;
	font-size: $font-size-h5;

	text-decoration: none;

	color: $color-white;

	display: block;

	@include breakpoint($mobilexxs) {
		padding: 10px 0;
	}
}


/**
 * Sub footer
 */

.c-sub-footer {
	@include breakpoint($mobilexxs) {
		padding: $spacing-default 0;
	}
}

.c-sub-footer__list {
	margin: 0;
}

.c-sub-footer__list li {
	display: inline-block;

	list-style: none;

	color: $color-body;
}

.c-sub-footer__list li a {
	padding: 25px $spacing-small;

	display: block;

	@include breakpoint($mobilexxs) {
		padding: $spacing-small;
	}
}

.c-sub-footer__list li a:first-child {
	padding-left: 0;
}

.c-sub-footer__list li a > .fa {
	font-size: 1.125em;
}

.c-sub-footer__divider {
	margin: 0 20px 0 10px;
}


/**
 * IE9 Layout fixes
 */

.ie9 .c-sub-footer__list {
	float: left;
}

.ie9 .top {
	float: right;
	margin-top: 20px;
}