/* ==========================================================================
   components.forms
   ========================================================================== */

.c-form {
	@include clearfix;
	width: 100%;
}

.c-form--center {
	max-width: 600px;

	margin: 0 auto;
}

.c-form__control {
	position: relative;
}

.c-form__control--align {
	display: inline-block;
}


/**
 * Form labels
 */

.c-form__label {
	font-family: $font-family-bold;
	font-size: $font-size-s;

	text-transform: uppercase;

	margin-bottom: 5px;
}

.c-form__label--big {
	font-family: $font-family-normal;
	font-size: 1.500em;

	text-transform: lowercase;

	color: #a3d7ee;

	@include breakpoint($mobilexxs) {
		font-size: 1.125em;
	}
}

.c-form .form__label {
	color: $color-primary;
	font-family: $font-family-bold;
	font-size: $font-size-s;
}

.c-form fieldset {
	padding: $spacing-default;

	background: $color-background-body;
	border: 1px $color-border solid;

	margin-bottom: $spacing-default;
}

.c-form .form__row {
	margin-bottom: $spacing-default;
}

.c-form .form__row:last-child {
	margin-bottom: 0;	
}

.c-form .buttons {
	float: left;

	margin-top: $spacing-default;

	@include breakpoint($mobilexxs) {
		float: none;
	}
}

.c-form .button {
	display: inline-block;

	padding: 1.3rem 3rem;

	font-family: $font-family-normal;
	font-weight: $font-weight-normal;
	text-align: center;
	text-shadow: none;

	border-radius: $border-radius;
	border: 0;

	background-color: $color-secondary;
	color: $color-white;

	transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;

	cursor: pointer;

	@include breakpoint($mobilexxs) {
	  width: 100%;

	  display: block;
	}
}

.c-form .form__note {
	float: right;
	margin-top: 44px;

	@include breakpoint($mobilexxs) {
		float: none;
		margin-top: 20px;
	}
}

.form-field.errors .text {
	border-color: red;
}

.form-field .errors {
	padding: 0;
	margin: 0;
	margin-top: 5px;
}

.form-field .errors li {
	color: red;
	list-style: none;
}

.form-field div {
	margin-bottom: 10px;
}