/*------------------------------------*\
    #IE8
\*------------------------------------*/

.ie8-container {
	width: 1024px;
	height: 700px;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	margin: 0 auto;
	margin-top: -350px;

}

.ie8-logo {
	width: 223px;
	height: 31px;
	margin: 0 auto;
	margin-bottom: 80px;
}

.ie8-heading {
	text-align: center;
	margin: 80px 0;
}

.ie8-heading-title {
	font: 700 60px/64px $font-family-normal;
	color: $color-primary;
}

.ie8-heading-paragraph {
	font: normal 24px/24px $font-family-normal;
	line-height: 1.5em;
	color: $color-body;
}

.ie8-content {
	@include clearfix;
}

.ie8-content-box {
	border-radius: $border-radius;
	width: 233px;
	height: 324px;
	float: left;
	margin-right: 30px;
	text-align: center;
	padding: 30px 25px;
	background: $color-white;
	border: 1px $color-border solid;
}

.ie8-content-box.last {
	margin-right: 0;
}

.ie8-content-logo {
	width: 73px;
	height: 75px;
	background: url('../img/browser_icons_sprite.png') no-repeat;
	display: block;
	margin: 0 auto;
	margin-bottom: 30px;
}

.ie8-content-logo.chrome {
	background-position: 0 0;
}

.ie8-content-logo.firefox {
	background-position: 0 -110px;
}

.ie8-content-logo.safari {
	background-position: 0 -220px;
}

.ie8-content-logo.internet {
	background-position: 0 -329px;
}

.ie8-content-box .btn {
	width: 100%;
	margin-top: 20px;
}


/**
 * IE9 FIXES
 */

.ie9 .page-heading {
	@include clearfix;
}

.ie9 .page-heading__title {
	float: left;
}

.ie9 .page-heading__button {
	float: right;
}

.ie9 .flex {
	@include clearfix;
}

.ie9 .testimonial__heading {
	@include clearfix;

	display: block;
}

.ie9 .testimonial__title {
	float: left;
	width: 130px;
}

.ie9 .testimonial__avatar {
	width: 100px;
	float: left;
}

.ie9 .referentie-buttons {
	@include clearfix;
}

.ie9 .referentie-button__item {
	float: left;
}

.ie9 .card-row {
	@include clearfix;
}