/* ==========================================================================
   components.pagination
   ========================================================================== */

.c-pagination {
	display: flex;
	justify-content: center;

	padding-bottom: 30px;
	margin-bottom: 0 !important;
}

.c-pagination li {
	padding: 10px 15px 6px 15px !important;

	text-align: center;

	list-style: none;
}

.c-pagination li:before {
	display: none;
}

.c-pagination li a {
	display: block;
}

.c-pagination li.is-current {
	background-color: $color-secondary;
	color: $color-white;
}

.c-pagination li.is-first a::before {
	content: "";

	width: 17px;
	height: 12px;

	display: inline-block;
	    
    margin-right: 7px;

    position: relative;
    top: 2px;

    background: url('../img/icon_arrow-left.svg') no-repeat;
}

.c-pagination li.is-last a::after {
	content: "";

	width: 17px;
	height: 12px;

	display: inline-block;
	    
    margin-left: 7px;

    position: relative;
    top: 2px;

    background: url('../img/icon_arrow.svg') no-repeat;
}