/* ==========================================================================
   trumps.utilities
   ========================================================================== */

/**
 * - Utility classes are helpers that are a small step away from
 *   inline styling. Use with caution.
 * - Always use !important on utility classes to be sure that their
 *   behaviour is always exactly the same.
 */

/**
 * Text alignment utilities.
 */

.u-text-left {
  text-align: left !important;
}

.u-text-center {
  text-align: center !important;
}

.u-text-right {
  text-align: right !important;
}

/**
 * Text size utilities.
 */

.u-text-small {
  font-size: $font-size-s !important;
}

.u-text-medium {
  font-size: $font-size-m !important;
}

.u-text-large {
  font-size: $font-size-l !important;
}

.u-text-h1 {
  font-size: $font-size-h1 !important;

  @include breakpoint($mobilexxs) {
    font-size: 3.6rem;
  }
}

.u-text-h2 {
  font-size: $font-size-h2 !important;
}

.u-text-h3 {
  font-size: $font-size-h3 !important;
}

/**
 * Text transform utilities.
 */

.u-text-lower {
  text-transform: lowercase !important;
}

.u-text-capitalize {
  text-transform: uppercase !important;
}

.u-text-normal {
  font-family: $font-family-normal;
  font-weight: 400 !important;
}

.u-text-bold {
  font-family: $font-family-bold;
  font-weight: 700 !important;
}



/**
 * Text color utilities.
 */

.u-text-primary {
  color: $color-primary !important;
}

.u-text-secondary {
  color: $color-secondary !important;
}

.u-text-tertiary {
  color: $color-tertiary !important;
}

.u-text-contrast {
  color: $color-white !important;
}

.u-text-body {
  color: $color-body !important;
}

/**
 * Spacing utilities.
 */

.u-row-spacing-small {
  margin-bottom: ($spacing-small/2) !important;
}

.u-row-spacing {
  margin-bottom: $spacing-small !important;
}

.u-row-spacing-medium {
  margin-bottom: $spacing-default !important;
}

.u-row-spacing-large {
  margin-bottom: $spacing-large !important;
}

.u-row-spacing-huge {
  margin-bottom: $spacing-huge !important;
}

.u-spacing-left {
  margin-left: $spacing-small !important;
}

.u-spacing-right {
  margin-right: $spacing-small !important;
}

.u-row-spacing-none {
  margin-bottom: 0 !important;
}

/**
 * Height utilities.
 */

.u-h370 {
  height: 370px !important;
}

.u-h400 {
  height: 400px !important;
}

.u-h420 {
  height: 420px !important;
}

.u-h550 {
  height: 550px !important;

  @include breakpoint($mobilexxs) {
    height: 400px !important;
  }
}

.u-h425 {
  height: 425px !important;
}

.u-h660 {
  height: 660px !important;
}

/**
 * Display utilities.
 */

.u-pull-left {
  float: left !important;
}

.u-pull-right {
  float: right !important;
}

.u-block {
  display: block !important;
}

.u-pull-top {
  margin-top: -319px;
}


/**
 * Position utilities
 */

.u-position-r {
  position: relative;
}

.u-width-auto {
  width: auto;
}



/**
 * Fa icon margins
 */

.fa-right {
  margin-right: 10px !important;
}

.fa-left {
  margin-left: 10px !important;
}


/**
 * Verticale alignment
 */

.va-container {
  width: 100%;
  
  display: table;
}

.va-middle {
  display: table-cell;
  vertical-align: middle;
}

.va-bottom {
  display: table-cell;
  vertical-align: bottom;
}


/**
 * Resets default list styles. Useful for navigation and such.
 */

.list-reset {
  margin: 0;
  padding: 0;

  list-style: none !important;
  li {
    padding-left: 0 !important;
  }
  li:before {
    display: none !important;
  }
}




/**
 * Overflows
 */

.u-hidden {
  overflow: hidden;
}

.u-hide {
  display: none;
}

/**
 * Mediaquery utilities.
 */

@media only screen and(min-width: 769px) {

  .u-m-show {
    display: none;
  }

}

@media only screen and(max-width: 768px) {

  .u-m-hide {
    display: none;
  }

}