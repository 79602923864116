/* ==========================================================================
   components.inputs
   ========================================================================== */

/**
 * Default input behaviour.
 */

.c-input {
  padding: 1.3rem 2rem;

  font-family: $font-family-blond;
  // line-height: ($font-baseline*1em);
  vertical-align: middle;
  color: $color-body;

  background-color: $color-white;

  border: 1px $color-border solid;

  transition: box-shadow 0.2s ease-in-out;
}

/**
 * Inputs with underline
 */

.c-input--underline {
  font-size: 1.500em;

  border: 0;
  border-bottom: 3px solid $color-white;

  background-color: transparent;

  color: $color-white;
}


/**
 * Craft forms
 */

.text {
  min-width: 100%;
  padding: 0.5em 1em;

  vertical-align: middle;
  color: $color-body;

  background-color: $color-white;

  border: 1px $color-border solid;
  border-radius: $border-radius;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  @include breakpoint($mobilexxs) {
    min-width: 100%;
  }
}