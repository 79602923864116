/* ==========================================================================
   components.radios
   ========================================================================== */

.c-radio {
  display: block;

  cursor: pointer;
}

/**
 * Custom radio styling.
 * For the purpose of maintaining a framework approach, we don't use classes on the child elements of the
 * radio initiator.
 */

.form__field input[type="radio"] {
  position: relative;

  display: inline-block;

  margin-right: 5px;

  vertical-align: middle;

  width: 20px;
  height: 20px;

  background-color: $color-white;

  border: 1px solid $color-border;
  border-radius: 50%;
}

/**
 * Custom radio styling when checked.
 */

.form__field input[type="radio"]:checked:after {
  // content: '\f111'; // Circle icon;
  content: '';

  position: absolute;
  top: 5px;
  left: 5px;

  width: 8px;
  height: 8px;

  background-color: $color-secondary;

  border-radius: 50%;
}

/**
 * The actual radio label. Correctly vertically aligns the label with the radio.
 */

.form__field input[type="radio"] + label {
  display: inline-block;

  vertical-align: middle;

  pointer-events: none;
}