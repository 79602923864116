/* ==========================================================================
   components.buttons
   ========================================================================== */

/**
 * Default button styles.
 * Every button gets the same default behaviour.
 */

.c-button {
  display: inline-block;

  padding: 1.3rem 3rem;

  font-family: $font-family-normal;
  font-weight: $font-weight-normal;
  text-align: center;
  text-shadow: none;

  border-radius: $border-radius;
  border: 0;

  background-color: transparent;

  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;

  cursor: pointer;

  @include breakpoint($mobilexxs) {
    width: 100%;

    display: block;
  }
}


/**
 * Primary button.
 */

.c-button--primary {
  color: $color-white;

  background-color: $color-secondary;
}

.c-button--primary:hover {
  background-color: darken($color-secondary, 5%);
  color: $color-white;
}


/**
 * Contrast buttons are used on dark background when there is no direct need
 * for a Call To Action (primary button). The padding of the button adjusts to
 * its borders
 */

.c-button--contrast {
  background-color: $color-white;
  color: $color-secondary;
}

.c-button--contrast:hover {
  background-color: $color-secondary;
  color: $color-white;
}


/**
 * Display block buttons.
 */

.c-button--block {
  display: block;
  
  width: 100%;
}

.c-button-group > .c-button {
  @include breakpoint($mobilexxs) {
    margin-bottom: ($spacing-default / 2);
  }
}

.c-button--stroke {
  color: $color-white;

  background-color: transparent;

  border: 2px rgba($color-white, 0.5) solid;

  border-radius: 0;

  padding: 1rem 4rem;
}

.c-button--stroke:hover {
  color: $color-secondary;
  background-color: $color-white;
}


/**
 * Button outline with animation
 */

.c-button-outline {
  height: 50px;
  width: 50px;

  font-family: $font-family-normal;
  font-weight: $font-weight-normal;

  background-color: transparent;

  border-radius: 0;

  display: inline-block;

  position: relative;

  transition: all 0.2s ease-in-out;

  span {
    display: none;
  }
}

.c-button-outline:after {
  @include fontawesome('\f105', 3rem); // Right arrow

  position: relative;
  top: 8px;
}

.c-button-outline:hover {
  // padding: 1.3rem 4rem;
}

.c-button-outline--contrast {
  border: 2px rgba($color-white, 0.5) solid;

  color: $color-white;
}

.c-button-outline--primary {
  border: 2px rgba($color-primary, 0.5) solid;

  color: $color-primary;
}

.c-button-outline--secondary {
  border: 2px rgba($color-secondary, 0.5) solid;

  color: $color-secondary;
}

.c-button-outline--tertiary {
  border: 2px rgba($color-tertiary, 0.5) solid;

  color: $color-tertiary;
}


/**
 * Button text
 */

.c-button--text {
  font-family: $font-family-normal;
  
  background-color: transparent;

  color: $color-secondary;

  padding-left: 0;
  padding-right: 0;

  @include breakpoint($mobilexxs) {
    text-align: left;
  }
}

.c-button--text:hover {
  color: $color-primary;
}

.c-button--text::after {
  content: "";

  display: inline-block;

  width: 17px;
  height: 12px;

  margin-left: 7px;

  position: relative;
  top: 2px;

  background: url('../img/icon_arrow.svg') no-repeat;

  transition: all .2s;
}

.c-button--text:hover::after {
  margin-left: 12px;
}


/**
 * Socials
 */

.c-button--small {
  color: $color-white;
}

.c-button--facebook {
  background-color: $color-facebook;

  &:hover {
    background-color: darken($color-facebook, 7%);
  }
}

.c-button--linkedin {
  background-color: $color-linkedin;

  &:hover {
    background-color: darken($color-linkedin, 7%);
  }
}

.c-button--twitter {
  background-color: $color-twitter;

  &:hover {
    background-color: darken($color-twitter, 7%);
  }
}

.c-button--googleplus {
  background-color: $color-googleplus;

  &:hover {
    background-color: darken($color-googleplus, 7%);
  }
}