/* ==========================================================================
   components.logo
   ========================================================================== */

.c-logo img {
	width: 156px;
	height: 66px;

	@include breakpoint($mobilexxs) {
		width: 140px;
	}
}