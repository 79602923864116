/* ==========================================================================
   elements.forms
   ========================================================================== */

/**
 * Inputs, textarea's and selects are 100% wide by default to fill
 * their defined layout.
 */

input,
textarea,
select {
  width: 100%;
}

input:focus {
  outline: none;
}